@import '../../mixins';

.icon-button {
	.icon {
		* {
			color: rgba(var(--text-color-rgb), var(--opacity-light));
		}
	
		&:hover {
			cursor: pointer;
			* {
				color: rgba(var(--text-color-rgb), var(--opacity-medium));
			}
		}
	}
}