.tab-content-pane {
	height: 352px;
	display: flex;
	flex-direction: row;
	background-color: #282828;
	border-radius: 0.75rem;
	width: fit-content;

	.left-sidebar-chooser {
		display: flex;
		background-color: #202020;
		flex-direction: column;
		max-height: inherit;
		overflow-y: scroll;
		border-radius: 0.75rem 0 0 0.75rem;

		.left-sidebar-option {
			cursor: pointer;
			padding: 1rem 2rem;
		}
	}
}
