.discography-page {
	h1 {
		margin-bottom: 2rem;
	}

	margin: 10vh 5vw;
	display: flex;
	flex-direction: column;
	
	.discography-link-list {
		display: flex;
		justify-content: space-between;
		overflow: scroll;

		.discography-link-item {
			padding: 1rem 2rem;
			justify-content: center;
			align-content: center;
			text-align: center;
			display: flex;
			flex-direction: column;
		}
	}
}
