$pc-min-width: 960px;	// PC screen size.
$tablet-min-width: 720px;	// Tablet screen size.
// $phone-min-width: 320px;	// Phone screen size.

@mixin responsive($media) {
	@if $media == phone {
		@media only screen and (max-width: $tablet-min-width - 1) {
			@content;
		}
	}
	@else if $media == tablet {
		@media only screen and (min-width: $tablet-min-width) and (max-width: $pc-min-width - 1) {
			@content;
		}
	}
	@else if $media == pc {
		@media only screen and (min-width: $pc-min-width) {
			@content;
		}
	}
	@else if $media == pc_tablet {
		@media only screen and (min-width: $tablet-min-width) {
			@content;
		}
	}
}
