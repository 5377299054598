@import 'mixins';

@font-face {
  font-family: 'projects';
  src: local('projects'), url('./fonts/projects.ttf') format('truetype');
}

@font-face {
  font-family: 'world conflict';
  src: local('world conflict'), url('./fonts/WorldConflictRegular-p26R.ttf') format('truetype');
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  // Colors
  --black: #000000;
  --space-gray: #4c4c4c;
  --electric-blue: #00ffff;
  --neon-green: #39ff14;
  --hot-pink: #ff69b4;
  --white: #ffffff;
  // Colors - rgb
  --black-rgb: 0, 0, 0;
  --space-gray-rgb: 76, 76, 76;
  --electric-blue-rgb: 0, 255, 255;
  --neon-green-rgb: 57, 255, 20;
  --hot-pink-rgb: 255, 105, 180;
  --white-rgb: 255, 255, 255;

  // Color assignments
  --main-bg-color: var(--black);
  --secondary-bg-color: var(--space-gray);
  --text-color: var(--white);
  // Color assignments - rgb
  --main-bg-color-rgb: var(--black-rgb);
  --secondary-bg-color-rgb: var(--space-gray-rgb);
  --text-color-rgb: var(--white-rgb);

  background-color: var(--main-bg-color);
  color: var(--text-color);

  --opacity-light: 0.4;
  --opacity-medium: 0.8;
  --opacity-heavy: 1;

  font-size: 1em;
}

.selectable {
  &:hover {
    background-color: #282828;
  }

  &.selected {
    background-color: rgba(var(--electric-blue-rgb), var(--opacity-medium));

    &:hover {
      background-color: rgba(var(--electric-blue-rgb), var(--opacity-light));;
    }
  }
}

.noscroll {
  overflow: hidden;
}

.hidden {
  display: none!important;
}

h1 {
  font-size: 2.5rem;
  @include responsive(pc) {
    font-size: 6rem;
  }
}

a {
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  color: var(--white);
  font-weight: 500;
}
