@import '../mixins';

.about-page {
	.first {
		background-image: url(../../../public/images/placeholder-image-4.jpg);
	}

	.second {
		background-image: url(../../../public/images/placeholder-image-5.jpg);
	}

	span.about {
		max-width: 70%;
		font-size: 1.1rem;
		@include responsive(pc) {
			font-size: 1.5rem;
			max-width: 70%;
		}
	}
}
