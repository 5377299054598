@import '../mixins.scss';

.parallax-container {
	// Dimensions
	width: 100%;
	height: 100vh;

	// Background
	background-size: cover;
	background-position: center;
	background-attachment: scroll;
	@include responsive(pc) {
		background-attachment: fixed;
	}

	// Display
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;
}
