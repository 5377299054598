@import '../mixins';

.home {
	.first {
		background-image: url(../../../public/images/placeholder-image-1.jpeg);
		h1.artist-name {
			font-family: 'world conflict';
			font-weight: 100;
			color: var(--text-color);
		}
	}

	.second {
		background-image: url(../../../public/images/placeholder-image-2.webp);
	}

	.third {
		background-image: url(../../../public/images/placeholder-image-3.jpg);
		&.youtube-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: relative;
		
			h2 {
				display: inline-block;
				position: absolute;
				top: 20vh;
			}
		
			.play-icon {
				position: absolute;
				font-size: 10rem;
			}
		}
	}

	div.separator {
		width: 100%;
		min-height: 40vh;
		background-color: var(--main-bg-color);
	}

	h2 {
		margin-bottom: 2rem;
	}
}