@import '../mixins.scss';

.discography-item {
	display: flex;
	flex-direction: column;

	.top-pane {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 100%;
		margin: 2rem 0;

		@include responsive(pc) {
			flex-direction: row;
		}

		.title {
			font-size: 2.5rem;
			font-weight: bold;
		}

		.discography-type {
			font-size: 1.75rem;
			margin-bottom: 1rem;
		}

		.attribute-row {
			font-size: 1rem;
			.attribute-name {
				font-weight: bold;
			}
		}

		.genres-list {
			padding-left: 1.5rem;
		}

		.art {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: center;

			@include responsive(pc) {
				width: 50%;
			}

			img {
				width: 100%;
				box-shadow: inset 0 2px 0px #dcffa6, 0 2px 5px #000;
				// border-radius: 0.4rem;
			}
		}

		.details-attribute {
			padding-top: 2rem;
		}

		.details {
			display: flex;
			flex-direction: column;
			width: 100%;

			@include responsive(pc) {
				width: 50%;
			}
		}
	}

	.tracklist {
		width: 100%;
		display: grid;
		grid-template-columns: [track-number] auto [track-title] 3fr [track-release-date] 1fr;
		grid-template-rows: auto;
		row-gap: 0.2rem;

		.track {
			display: contents;
			border-radius: 0.2rem;
			cursor: pointer;

			&.header-row {
				cursor: default;
				div {
					background-color: var(--main-bg-color);
					color: var(--text-color);
				}
			}

			.track-grid-item {
				background-color: var(--secondary-bg-color);
				padding: 1rem;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			
			.track-number {
				background-color: rgb(var(--neon-green-rgb));
				color: var(--space-gray);
				text-align: center;
			}
			
			.track-title {}

			.track-release-date {
				text-align: center;
			}
		}
	}
}
