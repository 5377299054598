.construction-page {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.box {
		border: 0.8em solid white;
		text-align: center;
		width: 50vw;
		max-width: 30em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&:before {
			content: "";
			display: flex;
			flex-direction: column;
			padding-top: 100%;
			align-content: center;
			justify-content: center;
		}

		.construction-message {
			position: absolute;
			width: inherit;
			max-width: 100%;
			overflow-wrap: break-word;
			font-size: 2em;
		}
	}
}
