.overlay {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
	inset: 0;
	background-color: rgba(var(--main-bg-color-rgb), var(--opacity-medium));
	z-index: 2;
}