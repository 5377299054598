#detail {
	&.menu-open {
		overflow: hidden;
	}
}

.return-to-top-button {
	position: fixed!important;
	z-index: 1;

	.icon {
		font-size: 4rem!important;
	}
}
