@import '../../mixins';

.corner-icon-button {
	position: absolute;
	margin: 1rem;
	@include responsive(pc) {
		margin: 2rem;
	}

	&.upper-right {
		top: 0;
		right: 0;
		bottom: auto;
		left: auto;
	}

	&.lower-right {
		top: auto;
		right: 0;
		bottom: 0;
		left: auto;
	}

	&.upper-left {
		top: 0;
		right: auto;
		bottom: auto;
		left: 0;
	}

	&.lower-left {
		top: auto;
		right: auto;
		bottom: 0;
		left: 0;
	}

	.icon {
		font-size: 3rem;
		@include responsive(pc) {
			font-size: 2rem;
		}
	}
}