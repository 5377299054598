@import '../mixins';

#menu {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
	inset: 0;

	ul {
		position: static;
		list-style: none;
		list-style-type: none;
		
		margin-left: 2rem;
		@include responsive(pc) {
			margin-left: 4rem;
		}

		li {
			font-size: 2rem;
			padding: 2rem;
			@include responsive(pc) {
				font-size: 3rem;
			}
			
			a {
				color: rgba(var(--text-color-rgb), var(--opacity-medium));

				&:hover {
					color: rgba(var(--text-color-rgb), var(--opacity-heavy));
				}
			}
		}
	}
}
